import styled from "styled-components";

import { Box } from "src/components";

export const Container = styled(Box)`
  width: 100%;
  max-width: 370px;
  margin: 200px auto 100px;
`;

export const Title = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  padding: 33px 60px 40px;
`;
