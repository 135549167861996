import React, { useEffect, FC, useMemo } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";

import {
  authorizeUser,
  setCheckout,
  setUserData,
  setUserAddresses,
} from "src/actions";
import { setCurrentWorkFlow } from "src/actions/user";
import { setWishlistUuid, setWishlistNoUuid } from "src/actions/wishlist";
import {
  refreshToken,
  isTokenExist,
  setToken,
  getCheckout,
  getUserData,
  getAddresses,
} from "src/api";
import { Header, PageWrapper, Footer } from "src/components";
import { ROUTES } from "src/constants";
import { ApplicationStore } from "src/store";
import {
  getBasketId,
  getFavoritesFromLocalStorage,
  setBasketId,
} from "src/utils";

import { Account } from "./Account";
import OrderInfo from "./Account/MyOrders/OrderInfo";
import { AuthPage } from "./Auth";
import { CatalogPage, BrandPage, CollectionPage } from "./Catalog";
import { Checkout } from "./Checkout";
import { YaPayResult } from "./Checkout/YaPayResult/YaPayResult";
import { ContactsPage } from "./ContactsPage";
import { EmptyPage } from "./EmptyPage";
import { FavoritesPage } from "./Favorites";
import { LinkPage } from "./LinkPage";
import { CardsPage } from "./LinkPage/CardsPage";
import { DeliveryPage } from "./LinkPage/Delivery";
import { Payment } from "./LinkPage/Payment";
import { PersonalCreate } from "./LinkPage/PersonalCreate";
import { RecomendationPage } from "./LinkPage/Recomendation";
import { ReturnPage } from "./LinkPage/Return";
import { VacancyPage, VacancySinglePage } from "./LinkPage/Vacancy";
import Magazine from "./Magazine";
import MagazineItemPage from "./Magazine/MagazineItemPage";
import { MainPage } from "./Main";
import { OutfitPage } from "./Outfit/OutfitPage";
import { Page404 } from "./Page404/Page404";
import { ShopPage } from "./ShopPage";
import { StaticPage } from "./StaticPage";
//import BonusProgram from "./StaticPage/BonusProgram";

const Router: FC = () => {
  const dispatch = useDispatch();
  const { info: userInfo } = useSelector(
    (state: ApplicationStore) => state.user
  );

  useEffect(() => {
    if (isTokenExist()) {
      refreshToken().then(({ data }) => {
        setToken(data.access_token);
        dispatch(authorizeUser({ ...data, userWorkFlow: "authenticated" }));
        getUserData().then(({ data }) => {
          dispatch(setUserData(data));
          setBasketId(data.basket_uuid);
        });
        getAddresses().then(({ data }) => {
          dispatch(setUserAddresses(data));
        });
      });
    } else {
      const tempBasketId = getBasketId();
      if (tempBasketId === null) {
        setBasketId("undefined");
        dispatch(
          setCurrentWorkFlow({
            userWorkFlow: "anonymous",
          })
        );
      } else if (tempBasketId === "undefined") {
        dispatch(
          setCurrentWorkFlow({
            userWorkFlow: "anonymous",
          })
        );
      } else {
        dispatch(
          setCurrentWorkFlow({
            userWorkFlow: "hasUuid",
          })
        );
      }
    }
  }, [dispatch]);

  const { userWorkFlow } = useSelector((state: ApplicationStore) => state.user);

  useEffect(() => {
    const checkoutId = getBasketId() || userInfo?.checkoutId;
    if (checkoutId && userWorkFlow) {
      if (userWorkFlow === "authenticated") {
        getCheckout(checkoutId).then(({ data }) => {
          dispatch(setCheckout(data));
          dispatch(setWishlistUuid(data.wish_list));
        });
      } else {
        if (userWorkFlow === "hasUuid") {
          getCheckout(checkoutId).then(({ data }) => {
            dispatch(setCheckout(data));
          });
        }
        const favorites = getFavoritesFromLocalStorage();
        if (favorites) {
          dispatch(setWishlistNoUuid(favorites));
        }
      }
    }
  }, [dispatch, userInfo, userWorkFlow]);

  const { pathname } = useLocation();

  const ogMetaTags = useMemo(() => {
    const ogData = [
      {
        property: "og:site_name",
        content: "https://leform.ru/",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:url",
        content: `https://leform.ru${pathname}`,
      },
    ];

    if (
      pathname === "/brands/" ||
      (pathname.indexOf("catalog") === -1 && pathname.indexOf("brands")) === -1
    ) {
      ogData.push({
        property: "og:image",
        content: "https://leback.leform.ru/img/storage/logo2.png",
      });
      return ogData;
    }
    return ogData;
  }, [pathname]);

  return (
    <>
      <HelmetProvider>
        <Helmet
          defer={false}
          title="LEFORM — Концептуальный интернет-магазин"
          meta={ogMetaTags}
        />
      </HelmetProvider>
      <PageWrapper>
        <Header isLogged={!!userInfo} />

        <Switch>
          <Route path={ROUTES.page404} component={Page404} />
          <Route
            path={ROUTES.sertificateMan}
            component={() => <CardsPage gender="Мужские" />}
          />
          <Route
            path={ROUTES.sertificateWoman}
            component={() => <CardsPage gender="Женские" />}
          />
          <Route
            path={ROUTES.payment}
            component={() => <Payment url="payment-page/" />}
          />
          <Route
            path={ROUTES.delivery}
            component={() => <DeliveryPage url="delivery-page/" />}
          />
          <Route
            exact
            path={`${ROUTES.myOrder}/:orderId`}
            component={OrderInfo}
          />
          <Route path={ROUTES.personalDataCeate} component={PersonalCreate} />
          <Route exact path={ROUTES.home} component={MainPage} />
          <Route path={ROUTES.catalog} component={CatalogPage} />
          <Route path={ROUTES.auth} component={AuthPage} />
          <Route path={ROUTES.brands} component={BrandPage} />
          <Route
            exact
            path={`${ROUTES.collections}:id`}
            component={CollectionPage}
          />
          <Route
            path={ROUTES.shops}
            component={() => <ShopPage url="shops-page" />}
          />
          <Route path={ROUTES.yapayresult} component={YaPayResult} />
          <Route path={ROUTES.checkout} component={Checkout} />
          <Route path={ROUTES.accountPage} component={Account} />
          <Route path={ROUTES.favorites} component={FavoritesPage} />
          <Route
            path={ROUTES.contacts}
            component={() => <ContactsPage url="contact-page" />}
          />
          <Route
            exact
            path={`${ROUTES.magazinePage}:code`}
            component={MagazineItemPage}
          />
          <Route exact path={ROUTES.magazinePage} component={Magazine} />

          <Route
            path={ROUTES.return}
            component={() => <ReturnPage url="return-page" />}
          />
          <Route
            path={ROUTES.recommendations}
            component={() => <RecomendationPage url="recommendation-page" />}
          />
          <Route
            path={`${ROUTES.vacancy}:code`}
            component={() => (
              <VacancySinglePage url={`${ROUTES.vacancy}:code`} />
            )}
          />
          <Route
            path={ROUTES.vacancy}
            component={() => <VacancyPage url="about/vacancies/" />}
          />
          <Route
            path={ROUTES.about}
            component={() => (
              <LinkPage
                seoTitle="О нас"
                seoDescription="О нас. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера"
                url="about/"
              />
            )}
          />
          <Route
            path={ROUTES.privacy}
            component={() => (
              <LinkPage
                seoTitle="Публичный договор оферта"
                seoDescription="Публичный договор оферта. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера"
                url="about/privacy/"
              />
            )}
          />
          <Route
            path={ROUTES.discounts}
            component={() => (
              <LinkPage
                seoTitle="Скидки"
                seoDescription="Скидки. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера"
                url="about/discounts/"
              />
            )}
          />
          <Route
            path={ROUTES.design}
            component={() => (
              <LinkPage
                seoTitle="Карта дизайнера"
                seoDescription="Карта дизайнера. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера"
                url="about/design/"
              />
            )}
          />
          <Route path="/account_bonus" component={() => <script></script>} />

          <Route
            exact
            path={`${ROUTES.bonusProgram}`}
            component={MagazineItemPage}
          />

          <Route
            strict
            exact
            path={`${ROUTES.staticPage}:page`}
            component={StaticPage}
          />

          <Route path={ROUTES.shortLinks} component={EmptyPage} strict />

          <Route path={ROUTES.outfits} component={OutfitPage} strict />

          <Redirect
            from={ROUTES.shortLinksSlash}
            to={ROUTES.shortLinks}
            strict
          />

          <Redirect to={ROUTES.home} />
        </Switch>
      </PageWrapper>

      <Footer />
    </>
  );
};

export default Router;
