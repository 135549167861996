import React, { FC, useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import { getVacancyContent } from "src/api/vacancy";
import { Preloader, Accordeon } from "src/components";
import { SeoMeta, VacancyData } from "src/types";
import { generateMetaTags } from "src/utils";

import {
  BackLinkEmpty,
  Container,
  VacancySinglePage,
  Info,
  InfoRow,
  NameH1,
} from "./VacancyPage.styles";

type IProps = {
  url: string;
};

const LinkPage: FC<IProps> = ({ url }) => {
  const history = useHistory();
  const [seoTitle, setSeoTitle] = useState("Вакансия в магазине LEFORM");
  const [seoDescription, setSeoDescription] = useState(
    "Вакансия в магазине LEFORM"
  );
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<VacancyData>({
    circs: "",
    city: "",
    detail_text: "",
    employment_type: "",
    name: "",
    requirements: "",
    responsibilities: "",
    schedule: "",
  });

  const query = useLocation();
  useEffect(() => {
    setIsLoading(true);
    getVacancyContent(query.pathname.slice(1))
      .then(({ data }) => {
        setContent(data);
      })
      .finally(() => setIsLoading(false));
  }, [query.pathname, url]);

  useEffect(() => {
    setSeoTitle("Вакансия в магазине LEFORM - " + content.name);
    setSeoDescription("Вакансия - " + content.name + " в магазине LEFORM");
  }, [content.name]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  const accordeonContent = (
    field: "circs" | "requirements" | "responsibilities"
  ) => {
    return <div dangerouslySetInnerHTML={{ __html: content[field] }}></div>;
  };
  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      <BackLinkEmpty onClick={() => history.goBack()}>
        <div className="back-arrow"></div>
        <span>Назад</span>
      </BackLinkEmpty>
      {isLoading ? (
        <Preloader />
      ) : (
        <VacancySinglePage>
          <NameH1>{content.name}</NameH1>
          <p
            className="mb-50"
            dangerouslySetInnerHTML={{ __html: content.detail_text }}
          ></p>
          <InfoRow>
            <Info>
              <h2>Город:</h2>
              <p>{content.city}</p>
            </Info>
            <Info>
              <h2>Занятость:</h2>
              <p>{content.employment_type}</p>
            </Info>
            <Info>
              <h2>График работы:</h2>
              <p>{content.schedule}</p>
            </Info>
          </InfoRow>
          <div className="accordeons">
            <Accordeon
              header="Обязанности"
              content={accordeonContent("responsibilities")}
            />
            <Accordeon
              header="Требования"
              content={accordeonContent("requirements")}
            />
            <Accordeon header="Условия" content={accordeonContent("circs")} />
          </div>
        </VacancySinglePage>
      )}
    </Container>
  );
};

export default LinkPage;
