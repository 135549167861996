import React, { FC } from "react";

import { OrderNumber, Title, Container, PaymentLink } from "./styles";
import { OrderCompletedProps } from "./types";

const OrderCompleted: FC<OrderCompletedProps> = ({
  orderNumber,
  paymentLink,
}) => {
  return (
    <Container>
      <Title>
        Спасибо!
        <br />
        Мы получили ваш заказ и совсем скоро свяжемся для подтверждения.
      </Title>
      <OrderNumber>Номер заказа: {orderNumber}</OrderNumber>
      {paymentLink && (
        <PaymentLink target="_blank" href={paymentLink}>
          Оплатить
        </PaymentLink>
      )}
    </Container>
  );
};
export default OrderCompleted;
