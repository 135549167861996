import React, { FC } from "react";

import { Promocode } from "src/components";
import { formatCurrency } from "src/utils";

import { TotalItem, Item, List, BoldText } from "./styles";
import { OrderInfoProps } from "./types";

const OrderInfo: FC<OrderInfoProps> = ({
  order,
  deliveryPrice,
  bonusValue,
}) => (
  <List>
    <Promocode />
    <Item>
      <span>Стоимость заказа: </span>
      <span>{formatCurrency(order.resultPrice)}</span>
    </Item>
    <Item>
      <span>Ваша скидка: </span>
      <BoldText>{formatCurrency(order.price - order.resultPrice)}</BoldText>
    </Item>
    {bonusValue > 0 && (
      <Item>
        <span>Оплата бонусами: </span>
        <BoldText>
          <span>{formatCurrency(Number(bonusValue))}</span>
        </BoldText>
      </Item>
    )}
    <Item>
      <span>Доставка: </span>
      <span>{formatCurrency(Number(deliveryPrice || 0))}</span>
    </Item>
    <TotalItem>
      <span>Итого: </span>
      <span>
        {formatCurrency(
          order.resultPrice +
            Number(deliveryPrice || 0) -
            Number(bonusValue || 0)
        )}
      </span>
    </TotalItem>
  </List>
);

export default OrderInfo;
