import { API } from "src/constants";

import { Http } from "../http";

import {
  MagazineResponse,
  MagazinePageResponse,
  MainPageMagazineResponse,
} from "./magazine.types";

export const getMagazines = (): MagazineResponse =>
  Http.get(`${API}/magazine?old_page_v=true`);

export const getMainPageMagazines = (): MainPageMagazineResponse =>
  Http.get(`${API}/magazine?for_main_page=1`);

export const getMagazinePage = (url: string): MagazinePageResponse =>
  Http.get(`${API}/magazine/${url}`);
