import { AxiosResponse } from "axios";

import { API } from "src/constants";

import { Http } from "../http";

import {
  DeliveriesResponse,
  PaymentMethodsResponse,
  FinishOrderResponse,
  FinishOrderRequest,
  FastOrderRequest,
  OrdersResponse,
  OrderDataResponse,
} from "./order.types";

const url = `${API}/checkout`;

export const getOrderDeliveries = (): Promise<
  AxiosResponse<DeliveriesResponse>
> => Http.post(url, { step: 1 });

export const getOrderPaymentMethods = (
  deliveryId: number
): Promise<AxiosResponse<PaymentMethodsResponse>> =>
  Http.post(url, {
    step: 2,
    delivery_id: deliveryId,
  });

export const finishOrder = (
  data: FinishOrderRequest
): Promise<AxiosResponse<FinishOrderResponse>> =>
  Http.post(url, {
    step: 3,
    ...data,
  });

export const fastOrder = (
  data: FastOrderRequest
): Promise<AxiosResponse<FinishOrderResponse>> =>
  Http.post(`${API}/checkout_oneclick`, data);

export const getOrdersUser = (
  userId: number,
  statusId?: string | number
): OrdersResponse =>
  Http.get(`${API}/auth/orders`, {
    params: { user_id: userId, status_id: statusId },
  });

export const getItemOrder = (orderId: string): OrderDataResponse =>
  Http.get(`${API}/auth/orders/${orderId}`);
