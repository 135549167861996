import React, { useCallback, FC } from "react";

import { useClickOutside, useControl } from "src/hooks";

import Glyphs from "../Icons/Glyphs";

import {
  Wrapper,
  SelectContainer,
  SelectHeader,
  SelectHeaderText,
  SelectList,
  SelectListItem,
  IconContainer,
  ArrowIcon,
  IconWrapper,
  FlexWrapper,
} from "./Select.styles";
import { SelectProps, SelectOption } from "./Select.types";

const Select: FC<SelectProps> = (props) => {
  const { options, value, onChange, className, disabled } = props;

  const { state: isOpened, deactivate: onClose, toggle } = useControl();
  const ref = useClickOutside<HTMLDivElement>(onClose);

  const handleSelect = useCallback(
    (option: SelectOption) => (): void => {
      onChange(option);
      onClose();
    },
    [onChange, onClose]
  );

  const labelOutput = (option: SelectOption | undefined) => {
    if (!option) {
      return <></>;
    }

    const isNews = option.value === "news";
    const isChosen = value?.value === (isNews ? "olds" : "news");

    if (option.value === "news" || option.value === "olds") {
      return (
        <FlexWrapper>
          По дате
          <IconWrapper isChosen={isChosen}>
            {isNews ? <Glyphs.ArrowFilterUp /> : <Glyphs.ArrowFilterDown />}
          </IconWrapper>
        </FlexWrapper>
      );
    } else {
      return <>{option.label}</>;
    }
  };

  return (
    <Wrapper ref={ref} isOpened={isOpened} className={className}>
      <SelectContainer className="SelectContainer">
        <SelectHeader
          onClick={() => {
            !disabled && toggle();
          }}
        >
          <SelectHeaderText isDisabled={disabled}>
            {labelOutput(value)}
          </SelectHeaderText>
          <IconContainer className="IconContainer" isInverted={!isOpened}>
            <ArrowIcon />
          </IconContainer>
        </SelectHeader>

        {isOpened && (
          <SelectList>
            {options.map((option) => (
              <div key={option.label}>
                {option.label !== "По умолчанию" ? (
                  <SelectListItem
                    onClick={handleSelect(option)}
                    /*className={option.disabled ? "disabled" : undefined}*/
                    isSelected={value?.label === option?.label}
                  >
                    {labelOutput(option)}
                  </SelectListItem>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </SelectList>
        )}
      </SelectContainer>
    </Wrapper>
  );
};

export default Select;
