import { debounce } from "lodash";
import React, { useState, useMemo, FC, useEffect, useRef } from "react";
import TagManager from "react-gtm-module";
import { useInView } from "react-intersection-observer";

import {
  FavoriteButton,
  HeartIcon,
  NewFavoriteButton,
} from "src/components/FavoriteButton";
import { useActiveSlider } from "src/context/SliderContext";
import { formatCurrency, parseImages, convertImageUrl } from "src/utils";

import {
  Labels,
  LabelItem,
  LabelItemSale,
  LabelItemBlack,
  LabelItemBlackSmall,
  LabelItemGreen,
  LabelItemPromo,
} from "../ProductLabel/ProductLabel.styles";

import {
  ContainerItem,
  ImageLink,
  ImageContainer,
  ImageImg,
  InfoContainer,
  Name,
  PriceContainer,
  Price,
  OldPrice,
  Picture,
  ButtonContainer,
  CheckoutButton,
  DeleteButton,
  CrossIcon,
  SizeList,
  SizeItem,
  ProductType,
  ProductNameContainer,
  ImageNavigationElement,
  SizeListOld,
  SizeItemOld,
  OldImageLink,
} from "./CatalogListItem.styles";
import { CatalogListItemProps, Impressions } from "./CatalogListItem.types";
import MobileSlider from "./MobileSlider/MobileCatalogSlider";

const CatalogListItem: FC<CatalogListItemProps> = (props) => {
  const {
    item,
    className,
    catalogClassName,
    onCheckout,
    onDelete,
    onCheckoutLabel,
    addToFavorites,
    order,
    categories,
    selectedItemID,
    onClickedMobileID,
    isViewed,
    loadMorePage,
    isWish,
  } = props;

  const { name, gallery, link } = item;
  const new_price = item.new_price || 0;
  const old_price = item.old_price || 0;
  const isFavorites = catalogClassName === "favourites";

  const itemName = item.brand_alter || item.brand.name;
  const productType = item.type.name;
  const images = useMemo(() => parseImages(gallery), [gallery]);

  const [isHelpVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isClickable, setIsClickable] = useState(false);

  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" &&
      ("ontouchstart" in window || navigator.maxTouchPoints > 0)
  );

  const { activeSliderId } = useActiveSlider();

  const sliderId = useRef(`slider-${Math.random().toString(36).substr(2, 9)}`)
    .current;

  const [showFavoriteButton, setShowFavoriteButton] = useState(false);

  useEffect(() => {
    if (activeSliderId !== null) {
      if (activeSliderId === sliderId) {
        setShowFavoriteButton(true);
      } else {
        setShowFavoriteButton(false);
      }
    }
  }, [activeSliderId, sliderId]);

  useEffect(() => {
    const updateIsMobile = debounce(() => {
      setIsMobile("ontouchstart" in window || navigator.maxTouchPoints > 0);
    }, 300);

    window.addEventListener("resize", updateIsMobile);
    return () => {
      window.removeEventListener("resize", updateIsMobile);
      updateIsMobile.cancel();
    };
  }, []);

  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  const itemSizes = item.offers
    .filter((offer) => {
      return offer.available;
    })
    .map((filteredOffer) => {
      return filteredOffer.size_name;
    });

  const clickProduct = (e?: React.MouseEvent) => {
    if (isMobile && e) {
      e.preventDefault();
    }
    const scroll_box = document.getElementById("scroll_content");
    const scrollY =
      window.scrollY >
        (+`${scroll_box?.scrollHeight}` * Number(loadMorePage)) /
          (+`${loadMorePage}` + 1) && !!loadMorePage
        ? `${
            window.scrollY -
            (+`${scroll_box?.scrollHeight}` * loadMorePage) /
              (+`${loadMorePage}` + 1)
          }`
        : window.scrollY;

    sessionStorage.setItem("_scrollPosition_", `${scrollY}`);

    handleProductSelect();
  };
  const touchProduct = (e: React.TouchEvent, link: string, id: number) => {
    if (catalogClassName !== "main") {
      return;
    }
    if (isMobile) {
      e.preventDefault();
      setCurrentImageIndex(images.length >= 1 ? 1 : 0);
      if (!selectedItemID) {
        setIsClickable(false);
        onClickedMobileID?.(id);
      } else {
        if (selectedItemID === id) {
          if (!isDragging) {
            window.location.href = link;
          }
        } else {
          setIsClickable(false);
          onClickedMobileID?.(id);
        }
      }
      setIsDragging(false);
    }
  };

  const stopTouchEvent = (link: string) => {
    if (catalogClassName !== "main") {
      return;
    }
    if (isClickable && !isDragging) {
      window.location.href = link;
    }
    setIsClickable(true);
  };

  useEffect(() => {
    if (inView) {
      const impressions: Impressions = {
        name: item.name,
        id: item.id,
        price: new_price,
        brand: item.brand.name,
        position: order,
      };

      if (categories) {
        impressions.category = categories;
      }

      if (isViewed) {
        impressions.list = "Product-Page-you-saw";
      }

      if (categories || isViewed || categories === "") {
        TagManager.dataLayer({
          dataLayer: {
            event: "impressions",
            ecommerce: {
              currencyCode: "RUB",
              impressions: [impressions],
            },
          },
        });
      }
    }
  }, [categories, inView, isViewed, item, new_price, old_price, order]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [zoneWidth, setZoneWidth] = useState(0);

  const divRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    if (divRef.current) {
      const componentWidth = (divRef.current as HTMLDivElement).offsetWidth;
      const zoneWidthCalced = componentWidth / images.length;
      setZoneWidth(zoneWidthCalced);
      const index = Math.floor(
        (event.clientX - (divRef.current.getBoundingClientRect().left || 0)) /
          zoneWidthCalced
      );
      handleMouseEnter(index);
    }
  };

  const handleMouseEnter = (index: number) => {
    if (index < 0) {
      setCurrentImageIndex(0);
    } else if (index >= images.length) {
      setCurrentImageIndex(images.length - 1);
    } else {
      setCurrentImageIndex(index);
    }
  };

  const imageOutput = useMemo(() => {
    const safeIndex = Math.max(
      0,
      Math.min(images.length - 1, currentImageIndex)
    );
    const imageUrl = images[safeIndex];
    const convertedImageUrl = convertImageUrl(imageUrl, 600, 824);
    return (
      <Picture className={"image-1"}>
        <ImageImg loading="lazy" src={convertedImageUrl} alt={name} />
      </Picture>
    );
  }, [images, currentImageIndex]);

  const handleProductSelect = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "product-click",
        ecommerce: {
          click: {
            products: [
              {
                name: item.name,
                id: item.id,
                price: new_price,
                brand: item.brand.name,
                category: categories,
                position: order,
              },
            ],
          },
        },
      },
    });
  };

  const imageNavigation = useMemo(() => {
    if (!inView) {
      return <></>;
    }
    if (!zoneWidth) {
      return <></>;
    }
    const innerWidth =
      (zoneWidth * images.length - images.length * 2 - 6) / images.length;
    return images.map((image, i) => {
      return (
        <ImageNavigationElement
          className="image-navigation"
          key={i}
          isActive={i === currentImageIndex}
          style={{ width: `${innerWidth}px` }}
        ></ImageNavigationElement>
      );
    });
  }, [currentImageIndex, inView, zoneWidth, images]);

  return (
    <ContainerItem
      className={`${catalogClassName} ${className}`}
      ref={ref}
      data-id={item.id}
    >
      <Labels className={catalogClassName}>
        {!(new_price < old_price) && item.new && (
          <LabelItem className={catalogClassName}>new</LabelItem>
        )}
        {item.is_super && (
          <LabelItemBlack className={catalogClassName}>
            supersale
          </LabelItemBlack>
        )}
        {item.is_finalsale && (
          <LabelItemGreen className={catalogClassName}>
            final sale
          </LabelItemGreen>
        )}
        {item.is_promo && (
          <LabelItemPromo className={catalogClassName}>
            -20% по промокоду HEAT
          </LabelItemPromo>
        )}
        {(!item.new || new_price < old_price) &&
          item.is_archive &&
          !item.is_super && (
            <LabelItemBlack className={catalogClassName}>
              archive sale
            </LabelItemBlack>
          )}
        {(!item.new || new_price < old_price) &&
          item.is_blacksale &&
          !item.is_finalsale &&
          !item.is_super && (
            <LabelItemBlackSmall className={catalogClassName}>
              sale
            </LabelItemBlackSmall>
          )}
        {!item.is_super &&
          !item.is_blacksale &&
          !item.is_finalsale &&
          !item.is_archive &&
          new_price < old_price && (
            <LabelItemSale className={catalogClassName}>sale</LabelItemSale>
          )}
      </Labels>

      {/* NOTE: This component is used exclusively on the main page.
It relies on legacy view rendering and logic patterns that may need updating.
Consider reviewing for refactoring opportunities to align with newer standards. */}
      {catalogClassName === "main" && (
        <OldImageLink
          to={link || "/"}
          aria-label={name}
          className={selectedItemID === item.id ? "selected" : ""}
          onClick={(e) => {
            clickProduct(e);
          }}
          onTouchStart={(e) => {
            touchProduct(e, link, item.id);
          }}
          onTouchEnd={() => {
            stopTouchEvent(link);
          }}
          onTouchCancel={() => {
            stopTouchEvent(link);
          }}
          onTouchMove={() => setIsDragging(true)}
        >
          {!!itemSizes.length && isMobile && (
            <SizeListOld className={"sizeListMobile"}>
              {itemSizes.map((size) => (
                <SizeItemOld key={size}>{size}</SizeItemOld>
              ))}
            </SizeListOld>
          )}
          {images?.[0] && (
            <ImageContainer className={catalogClassName}>
              <Picture className={"image-0"}>
                <ImageImg
                  loading="lazy"
                  src={convertImageUrl(images[0], 600, 824)}
                  alt={name}
                />
              </Picture>
              {images?.[1] && (
                <Picture className={"image-1"}>
                  <ImageImg
                    loading="lazy"
                    src={convertImageUrl(images[1], 600, 824)}
                    alt={name}
                  />
                </Picture>
              )}
            </ImageContainer>
          )}
        </OldImageLink>
      )}

      {/* NEW VIEW AND LOGIC */}
      {catalogClassName !== "main" && (
        <ImageLink
          to={link || "/"}
          aria-label={name}
          className={selectedItemID === item.id ? "selected" : ""}
          onClick={(e) => {
            clickProduct(e);
          }}
          onTouchStart={(e) => {
            touchProduct(e, link, item.id);
          }}
          onTouchEnd={() => {
            stopTouchEvent(link);
          }}
          onTouchCancel={() => {
            stopTouchEvent(link);
          }}
          onTouchMove={() => setIsDragging(true)}
        >
          {images?.length > 0 && isMobile && (
            <MobileSlider
              images={images}
              className="catalog"
              altName={itemName}
              link={link}
              onProductSelect={handleProductSelect}
              sliderId={sliderId}
            />
          )}
          {!isMobile && images?.[0] && (
            <ImageContainer
              className={catalogClassName}
              onMouseMove={handleMouseMove}
              ref={divRef}
            >
              {imageNavigation}
              <Picture className={"image-0"}>
                <ImageImg
                  src={convertImageUrl(images[0], 600, 824)}
                  alt={itemName}
                />
              </Picture>
              {imageOutput}
            </ImageContainer>
          )}
        </ImageLink>
      )}

      {catalogClassName === "catalog" && (
        <>
          {!isMobile && (
            <FavoriteButton
              onClick={() => addToFavorites?.(item)}
              isCatalog={true}
              variant="white"
              aria-label="Добавить в избранное"
              isFavourite={isWish}
              isHelpVisible={isHelpVisible}
            >
              <HeartIcon />
            </FavoriteButton>
          )}
          {isMobile && showFavoriteButton && (
            <NewFavoriteButton
              onClick={() => addToFavorites?.(item)}
              isCatalog={true}
              variant="white"
              aria-label="Добавить в избранное"
              isFavourite={isWish}
              isHelpVisible={isHelpVisible}
            >
              <HeartIcon />
            </NewFavoriteButton>
          )}

          {!!itemSizes.length && !isMobile && (
            <SizeList className={"sizeListDesktop"}>
              {itemSizes.map((size) => (
                <SizeItem key={size}>{size}</SizeItem>
              ))}
            </SizeList>
          )}
        </>
      )}
      <InfoContainer className={catalogClassName}>
        <ProductNameContainer
          onMouseDown={(e) => {
            clickProduct(e);
          }}
          onTouchStart={(e) => {
            touchProduct(e, link, item.id);
          }}
          onTouchMove={() => setIsDragging(true)}
          aria-label={name}
          to={link || "/"}
        >
          <ProductType className={catalogClassName}>{productType}</ProductType>
          <Name className={catalogClassName}>{itemName}</Name>
        </ProductNameContainer>
        <PriceContainer className={catalogClassName}>
          {old_price > new_price && (
            <OldPrice>{formatCurrency(old_price)}</OldPrice>
          )}
          <Price className={catalogClassName}>
            {formatCurrency(new_price)}
          </Price>
        </PriceContainer>
      </InfoContainer>
      {catalogClassName !== "main" && !!itemSizes.length && isMobile && (
        <SizeList className={"sizeListMobile"}>
          {itemSizes.map((size) => (
            <SizeItem key={size}>{size}</SizeItem>
          ))}
        </SizeList>
      )}
      {isFavorites && (
        <ButtonContainer>
          <CheckoutButton
            variant="white"
            onClick={() => void onCheckout?.(item)}
          >
            {onCheckoutLabel || "В корзину"}
          </CheckoutButton>
          <DeleteButton
            aria-label="Удалить"
            onClick={() => void onDelete?.(item)}
          >
            <CrossIcon />
            Удалить
          </DeleteButton>
        </ButtonContainer>
      )}
    </ContainerItem>
  );
};

export default CatalogListItem;
