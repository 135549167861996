import React, { FC, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

import { convertImageUrl } from "src/utils";

import {
  Picture,
  ImageContainer,
  ImageImg,
  ContainerItem,
  ImageLink,
} from "../CatalogListItem/CatalogListItem.styles";

import { PromoCardProps } from "./PromoCard.types";

const PromoCard: FC<PromoCardProps> = (props) => {
  const { search } = useLocation();

  const { item } = props;
  const {
    code,
    catalogClassName,
    image_src,
    page_url,
    linkto,
    position,
  } = item;

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      TagManager.dataLayer({
        dataLayer: {
          event: "promo-impression",
          ecommerce: {
            promoView: {
              promotions: [
                {
                  id: page_url || "without-link",
                  name: code,
                  creative: "catalog-banner-code",
                  position: `${page_url}${search}.position-card-${position}`,
                },
              ],
            },
          },
        },
      });
    }
  }, [inView]);

  const clickPromoCard = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "promo-click",
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: page_url || "without-link",
                name: code,
                creative: "catalog-banner-code",
                position: `${page_url}${search}.position-card-${position}`,
              },
            ],
          },
        },
      },
    });
  };

  return (
    <>
      <ContainerItem ref={ref}>
        <ImageLink to={linkto}>
          <ImageContainer className={catalogClassName}>
            <Picture>
              <ImageImg
                src={convertImageUrl(image_src, 800, 1100)}
                loading="lazy"
                onClick={() => clickPromoCard()}
              />
            </Picture>
          </ImageContainer>
        </ImageLink>
      </ContainerItem>
    </>
  );
};

export default PromoCard;
