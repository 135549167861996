import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { formatCurrencyWithoutSymbol } from "src/utils";

import {
  BonusContainer,
  BoldText,
  BonusButton,
  BonusInfoContainer,
  BonusInput,
  BonusTitle,
  BonusText,
  Slider,
  ToggleContainer,
  ErrorMessage,
  BonusFieldContainer,
  BonusFieldInnerContainer,
} from "./OrderBonus.styles";
import { OrderBonusProps } from "./OrderBonus.types";

const OrderBonus: FC<OrderBonusProps> = ({
  bonusData,
  onChange,
  totalPrice,
  deductedBonusValue,
}) => {
  const [isSliderActive, setIsSliderActive] = useState(false);
  const [bonusValue, setBonusValue] = useState("");
  const [error, setError] = useState("");

  const availableBonus = useMemo(() => {
    return bonusData.available ? Math.floor(bonusData.available) : 0;
  }, [bonusData.available]);

  const deductibleBonusAmount = useMemo(() => {
    if (availableBonus && bonusData.percentage && totalPrice) {
      const minOrderSum = Math.floor(totalPrice * +bonusData.percentage);
      return minOrderSum < availableBonus ? minOrderSum : availableBonus;
    } else {
      return 0;
    }
  }, [availableBonus, bonusData.percentage]);

  useEffect(() => {
    onChange(0);
  }, [totalPrice]);

  const handleBonusValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (error) {
      setError("");
    }
    if (value === "") {
      setBonusValue("");
      return;
    }
    value = value.replace(/^0+/, "");
    if (!/^[1-9]\d*$/.test(value)) {
      return;
    }
    setBonusValue(value);
  };

  const handleBonusApply = () => {
    if (deductedBonusValue) {
      setBonusValue("");
      onChange(0);
      return;
    }
    if (bonusValue === "") {
      return;
    }

    if (+bonusValue <= deductibleBonusAmount) {
      onChange(+bonusValue);
      setError("");
    } else if (+bonusValue <= availableBonus) {
      setError("Невозможно применить");
    } else {
      setError("Недостаточно бонусов");
    }
  };

  const isAutoCompletion = useMemo(
    () =>
      isSliderActive &&
      +deductibleBonusAmount !== +bonusValue &&
      +deductibleBonusAmount > 0,
    [isSliderActive, bonusValue, deductibleBonusAmount]
  );

  const getBonusWordForm = (value: number): string => {
    const n = Math.abs(value) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) return "бонусов";
    if (n1 > 1 && n1 < 5) return "бонуса";
    if (n1 === 1) return "бонус";
    return "бонусов";
  };

  const getWriteOffWordForm = (value: number): string => {
    const n = Math.abs(value) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) return "списано";
    if (n1 === 1) return "списан";
    return "списано";
  };

  const getAvailableWordForm = useCallback((value: number): string => {
    const n = Math.abs(value) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) return "Доступно";
    if (n1 === 1) return "Доступен";
    return "Доступно";
  }, []);

  if (!availableBonus) {
    return <></>;
  }

  return (
    <BonusContainer>
      <BonusInfoContainer>
        <div>
          <BonusTitle>Использовать бонусы</BonusTitle>
          <BonusText>
            У вас{" "}
            <BoldText>{formatCurrencyWithoutSymbol(availableBonus)}</BoldText>{" "}
            {getBonusWordForm(availableBonus)}.{" "}
            {deductedBonusValue ? (
              <>
                Будет {getWriteOffWordForm(deductedBonusValue)}{" "}
                <BoldText>
                  {formatCurrencyWithoutSymbol(deductedBonusValue)}
                </BoldText>{" "}
                {getBonusWordForm(deductedBonusValue)}{" "}
              </>
            ) : (
              <>
                {" "}
                {getAvailableWordForm(deductibleBonusAmount)} к списанию{" "}
                <BoldText
                  className={
                    isAutoCompletion && !deductedBonusValue ? "clickable" : ""
                  }
                  onClick={() => {
                    if (isAutoCompletion) {
                      setBonusValue(String(deductibleBonusAmount));
                    }
                  }}
                >
                  {formatCurrencyWithoutSymbol(deductibleBonusAmount)}
                </BoldText>{" "}
                {getBonusWordForm(deductibleBonusAmount)}
              </>
            )}
          </BonusText>
        </div>
        <ToggleContainer
          isActive={isSliderActive}
          onClick={() => {
            setIsSliderActive(!isSliderActive);
            setBonusValue("");
            onChange(0);
          }}
        >
          <Slider isActive={isSliderActive} />
        </ToggleContainer>
      </BonusInfoContainer>
      <BonusFieldContainer isActive={isSliderActive}>
        <BonusFieldInnerContainer>
          <BonusInput
            value={bonusValue}
            isInputEnded={false}
            onChange={handleBonusValueChange}
            placeholder="Количество бонусов"
            type="text"
            name="bonus"
            aria-label="Количество бонусов"
            disabled={deductedBonusValue === 0 ? false : true}
          />
          <BonusButton
            onClick={() => {
              handleBonusApply();
            }}
          >
            {deductedBonusValue ? "Отменить" : "Применить"}
          </BonusButton>
        </BonusFieldInnerContainer>
      </BonusFieldContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </BonusContainer>
  );
};

export default OrderBonus;
