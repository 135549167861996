import { CheckoutTypes } from "src/store";
import { SetCheckout } from "src/store/checkout";
import { CheckoutData } from "src/types";

export const setCheckout = (data: CheckoutData): SetCheckout => ({
  type: CheckoutTypes.SET,
  payload: {
    id: data.basket.id,
    uuid: data.basket.uuid,
    items: data.basket.items,
    discountText: data.basket.discount_text,
    price: data.basket.price,
    resultPrice: data.basket.result_price,
  },
});
