import { ShortProductOffer, WishlistItem } from "src/types";

import { SortItems } from "./other";

export type CatalogListItem = {
  brand_alter: null | string;
  type: {
    id: number;
    name: string;
    code: string;
  };
  id: number;
  name: string;
  new_price: number;
  old_price: number;
  article: string;
  code: string;
  brand: {
    id: number;
    name: string;
    code: string;
  };
  gallery: string | string[];
  is_wish: boolean;
  link: string;
  offers: ShortProductOffer[];
  new: boolean;
  is_archive: boolean;
  is_blacksale: boolean;
  is_finalsale: boolean;
  is_promo: boolean;
  is_super: boolean;
  id_1c: string;
};

export type LocalStorageFavoriteItem = {
  product_id: number;
  offer_id?: number | undefined;
  created_at: string;
};

export type ProductLabelType =
  | "super"
  | "archive"
  | "sale"
  | "new"
  | "blacksale"
  | "final"
  | "is_promo"
  | "";

export type FavoriteItem = {
  id: number;
  code: string;
  old_price: number;
  new_price: number;
  brand_id: number;
  brand_name: string;
  link: string;
  name: string;
  type_name: string;
  type_code: string;
  label: ProductLabelType;
  gallery: string | string[];
  offers: FavoriteOfferType[];
  product_label: string;
  created_at: string;
};

export type FavoriteOfferType = {
  id: number;
  size_name: string;
  isWish: boolean;
  available: boolean;
};

export type CatalogFavoriteList = {
  items: FavoriteItem[];
};

export type favoriteItemsList = {
  items: WishlistItem[];
};

export type CatalogLastViewsItem = {
  id: number;
  code: string;
  old_price: number;
  new_price: number;
  brand_code: string;
  brand_name: string;
  link: string;
  type_name: string;
  gallery: string | string[];
  label: ProductLabelType;
  name: string;
};

export type CatalogLastViewsList = {
  items: CatalogLastViewsItem[];
};

// TODO: Дополнить список
export enum FilterType {
  checkbox = "checkbox",
  radio = "radio",
  slider = "slider",
}

export type FilterValue = {
  id: number | string;
  name: string | number | boolean;
  code?: string;
};

export type FilterNestedSizeValue = {
  category: string;
  id: number | string;
  name: string | number | boolean;
  values: FilterValue[];
};

export type FilterSizeValue = {
  id: number | string;
  name: string | number | boolean;
  values: FilterValue[];
};

export type Filter = {
  code: string;
  name: string;
  type: FilterType;
  sort: string;
  values: FilterValue[];
  sizevalues?: FilterSizeValue[];
  sizearrayvalues?: FilterNestedSizeValue[];
};

export type CatalogListRequiredData = {
  items: CatalogListItem[];
  paginate: {
    total: number;
    current: number;
  };
};

export type SectionTree = {
  code: string;
  id: number;
  id_1c: string;
  name: string;
  url: string;
  alter_title: string;
};

export type CatalogListData = CatalogListRequiredData & {
  settings: {
    sort: {
      allowed: SortItems;
    };
    filter: Filter[];
    per_page: {
      allowed: [];
      default: number;
    };
    q: string;
  };
  brand: {
    id: number;
    name: string;
    code: string;
    detail_text: string;
    gallery: string | string[];
  };
  section_tree?: SectionTree[];
  seo: SeoData;
  filterPrice?: {
    min_price: number;
    max_price: number;
  };
  categoryBanner?: {
    id: number;
    image_src: string;
    is_active: boolean;
    linkto: string;
    page_url: string;
    position: number;
  };
  categoryBanners?: any[]; //  eslint-disable-line @typescript-eslint/no-explicit-any
};

export type SeoData = {
  title: string;
  description: string;
  seoh1: string | null;
};

export type SeoParams = {
  [key: string]: any; //  eslint-disable-line @typescript-eslint/no-explicit-any
};
