import { merge } from "lodash";
import React, { Fragment, FC, ChangeEvent } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";

import MultiRangeSlider from "src/components/MultiRangeSlider/MultiRangeSlider";
import { stringifyConfig } from "src/constants";
import { useQueryParams } from "src/hooks";
import { ApplicationStore } from "src/store";

import { Filter, FilterValue } from "../../types";

import {
  BoxWrapper,
  Block,
  InputContainer,
  InnerBlock,
} from "./CatalogFilters.styles";
import { CatalogFiltersProps } from "./CatalogFilters.types";
import {
  deserializeFilters,
  serializeFilters,
  setFilterValues,
} from "./CatalogFilters.utils";
import { Collapse, Input, Scrollbar, FilterWithSearch } from "./elements";

const CatalogFilters: FC<CatalogFiltersProps> = (props) => {
  const { userData } = useSelector((state: ApplicationStore) => state.user);
  const { filters, isLoading, className, filterPrice } = props;

  const { queryParams, setQueryParams, getNewPath } = useQueryParams({
    arrayFormat: "bracket",
  });

  const addParams: any = getNewPath(window.location.pathname); //  eslint-disable-line @typescript-eslint/no-explicit-any
  const deserializedFilters = deserializeFilters(
    queryParams,
    filters,
    addParams
  );

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    filter: Filter,
    value?: FilterValue
  ) => {
    event.persist();
    const newFilters = serializeFilters(
      merge(deserializedFilters, setFilterValues(event)),
      filters
    );

    const currentFilterChanged = Object.entries(setFilterValues(event));
    let content = undefined;
    if (value) {
      content = value.name;
    }
    if (filter.name === "Цена") {
      content = `${currentFilterChanged[0][0]} - ${currentFilterChanged[0][1]}`;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "filters",
        name: filter.name,
        content,
        userId: userData?.userId,
      },
    });

    const code = event?.target?.dataset?.code
      ? event.target.dataset.code
      : undefined;

    const filterCode = event.target.name
      .replace("filter[", "")
      .replace("]", "")
      .split(":")[0];

    const seoParams = {
      [filterCode]: code,
    };

    setQueryParams(
      { ...newFilters, page: undefined },
      false,
      stringifyConfig,
      undefined,
      seoParams,
      filters
    );
  };

  const getValues = (filter: Filter, value: FilterValue) => {
    return (
      <InputContainer key={value.id}>
        <Input
          filterValue={value}
          filterType={filter.type}
          filterCode={filter.code}
          onChange={(
            event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
          ) => {
            if (queryParams["filter[min_price]"]) {
              delete queryParams["filter[min_price]"];
            }
            if (queryParams["filter[max_price]"]) {
              delete queryParams["filter[max_price]"];
            }
            handleChange(event, filter, value);
          }}
          values={deserializedFilters}
          disabled={isLoading}
        />
      </InputContainer>
    );
  };

  return (
    <BoxWrapper className={className}>
      {filters.map(
        (filter) =>
          !!filter.values.length && (
            <Fragment key={filter.name}>
              {filter.name === "Бренд" ? (
                <FilterWithSearch
                  filter={filter}
                  values={deserializedFilters}
                  disabled={isLoading}
                  onChange={(
                    event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
                  ) => {
                    handleChange(event, filter);
                  }}
                />
              ) : (
                <Block>
                  <Collapse title={filter.name}>
                    {filter.name !== "Цена" && filter.name !== "Размер" && (
                      <Scrollbar>
                        {filter.values.map(
                          (value) =>
                            Object.keys(value).length !== 0 &&
                            getValues(filter, value)
                        )}
                      </Scrollbar>
                    )}
                    {filter.name === "Размер" && (
                      <div>
                        {filter.name === "Размер" && (
                          <InputContainer className="filterInner">
                            {filter.sizevalues &&
                              filter.sizevalues.map((value) =>
                                getValues(filter, value)
                              )}
                            {!!filter.sizearrayvalues?.values &&
                              filter.sizearrayvalues.map((sizearray) => {
                                return (
                                  <>
                                    <Collapse
                                      className="inner-filter"
                                      title={sizearray.category}
                                    >
                                      {sizearray &&
                                        sizearray.values.map((value) => {
                                          return (
                                            <InnerBlock>
                                              {getValues(filter, value)}
                                            </InnerBlock>
                                          );
                                        })}
                                    </Collapse>
                                  </>
                                );
                              })}
                          </InputContainer>
                        )}
                      </div>
                    )}
                    {filter.name === "Цена" && (
                      <MultiRangeSlider
                        min={filterPrice ? +filterPrice.min_price : 0}
                        max={filterPrice ? +filterPrice.max_price : 1000000}
                        minValQuery={queryParams["filter[min_price]"] || null}
                        maxValQuery={queryParams["filter[max_price]"] || null}
                        onChange={(
                          event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
                        ) => {
                          handleChange(event, filter);
                        }}
                      />
                    )}
                  </Collapse>
                </Block>
              )}
            </Fragment>
          )
      )}
    </BoxWrapper>
  );
};

export default CatalogFilters;
