import React, { FC } from "react";

export const OutfitPage: FC = () => {
    return (
        <>
            <div id="modatech-widgets">

            </div>
        </>
    );
};