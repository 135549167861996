import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { setUserData, setCheckout } from "src/actions";
import {
  finishOrder,
  getUserData,
  getCheckout,
  getOrderPaymentMethods,
} from "src/api";
import { LoaderButton, Preloader } from "src/components";
import BasketErrorModal from "src/components/BasketErrorModal";
import { PRIVACY_POLICY, ROUTES } from "src/constants";
import { ApplicationStore } from "src/store";
import { Delivery, PaymentMethod, UserAddress, Shop } from "src/types";

import { getBasketId, getCookie } from "../../utils";

import {
  Accordeon,
  ChoosePaymentMethod,
  CheckoutHeadline,
  OrderCompleted,
  OrderItemList,
  OrderBonus,
  ReturnLink,
} from "./elements";
import { ChooseDelivery } from "./elements/ChooseDelivery";
import { DeliverySelectHandler } from "./elements/ChooseDelivery/types";
import {
  Layout,
  OrderCompletedWrapper,
  CheckoutContent,
  Text,
  LinkInPersonal,
  BackLinkEmpty,
  OrderErrorWrapper,
} from "./elements/styles";

const Checkout: FC = () => {
  const { userData } = useSelector((state: ApplicationStore) => state.user);
  const [isReady, setIsReady] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const checkout = useSelector((s: ApplicationStore) => s.checkout);
  const { isAuthorized, userWorkFlow } = useSelector(
    (state: ApplicationStore) => state.user
  );
  const [isShowPreloader, setIsShowPreloader] = useState(true);

  const [cid, setCID] = useState("");

  const [delivery, setDelivery] = useState<Delivery>();
  const [deliveryPrice, setDeliveryPrice] = useState<string>();
  const [address, setAddress] = useState<UserAddress>();
  const [shop, setShop] = useState<Shop>();
  const [comment, setComment] = useState<string>();
  const [payment, setPayment] = useState<PaymentMethod>();
  const [orderId, setOrderId] = useState<number>();
  const [paymentLink, setPaymentLink] = useState<string>();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deliveryError, setDeliveryError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isShopId, setIsShopId] = useState<boolean>(false);
  const [isPayment, setIsPayment] = useState<boolean>(false);

  const { items } = useSelector((state: ApplicationStore) => state.checkout);

  const isAllItemsAvailable = useMemo(() => {
    return !items.find((item) => !item.in_stock);
  }, [items]);

  const isSubmitButtonDisabled = useMemo(() => {
    return (
      !delivery ||
      !payment ||
      isLoading ||
      !isAllItemsAvailable ||
      !isShopId ||
      !isPayment ||
      deliveryError ||
      (isReady && payment?.code === "yapay") ||
      (isReady && payment?.code === "ysplit")
    );
  }, [
    delivery,
    payment,
    isLoading,
    isAllItemsAvailable,
    isShopId,
    isPayment,
    deliveryError,
    isReady,
  ]);

  const [prevItemsLength, setPrevItemsLength] = useState(checkout.items.length);
  const [deductedBonusValue, setDeductedBonusValue] = useState<number>(0);

  useEffect(() => {
    if (prevItemsLength !== checkout.items.length) {
      if (delivery) {
        getOrderPaymentMethods(delivery.id).then(({ data }) => {
          setDeliveryPrice(data.basket.delivery_price.toString());
          if (delivery.id === 6) {
            if (data.basket.disabled) {
              if (data.basket.error) {
                deliveryErrorHandle(data.basket.disabled, data.basket.error);
              }
            } else {
              deliveryErrorHandle(data.basket.disabled, "");
            }
          } else {
            deliveryErrorHandle(false, "");
          }
        });
      }
      if (!items.length) {
        //history.goBack();
      }
      setPrevItemsLength(checkout.items.length);
    }
  }, [checkout, delivery, items.length, prevItemsLength]);
  useEffect(() => {
    const option = localStorage.getItem("authorizedInCheckout");

    TagManager.dataLayer({
      dataLayer: {
        event: "checkout",
        ecommerce: {
          checkout: {
            actionField: { step: 2, option: option },
            products,
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    if (payment) {
      localStorage.setItem("payment_type", JSON.stringify(payment));
    }
  }, [payment]);

  const products = useMemo(
    () =>
      checkout.items.map((itemProduct) => ({
        name: itemProduct.product.name,
        id: itemProduct.product.id,
        price: itemProduct.discount_price || itemProduct.price,
        brand: itemProduct.product.brand.name,
        category: itemProduct.section_tree
          .map((category) => category.name)
          .join("/"),
        quantity: itemProduct.quantity,
        size: itemProduct.size,
      })),
    [checkout]
  );

  const handleDelivery: DeliverySelectHandler = useCallback(
    (delivery, meta) => {
      setDelivery(delivery);
      setShop(meta.shop);
      setAddress(meta.address);
      setComment(meta.comment);

      localStorage.setItem("meta", JSON.stringify(meta));
      localStorage.setItem("delivery", JSON.stringify(delivery));

      if (delivery?.id === 6) {
        meta.shop === undefined ? setIsShopId(false) : setIsShopId(true);
      } else {
        setIsShopId(true);
      }
    },
    [products]
  );

  useEffect(() => {
    if (delivery?.id === 4) {
      payment?.id === 2 ? setIsPayment(false) : setIsPayment(true);
    } else {
      setIsPayment(true);
    }
  }, [delivery, payment]);

  useEffect(() => {
    getUserData().then(({ data }) => {
      dispatch(setUserData(data));
      getCheckout(data.basket_uuid).then(({ data }) => {
        dispatch(setCheckout(data));
        setIsShowPreloader(false);
      });
    });
  }, []);
  useEffect(() => {
    if (!cid) {
      const cidC = getCookie("_ga")?.slice(6);
      if (cidC) {
        setCID(cidC);
      }
    }
  }, [cid]);

  function handleSubmit() {
    setIsLoading(true);
    if (delivery && payment) {
      const option = localStorage.getItem("authorizedInCheckout");
      TagManager.dataLayer({
        dataLayer: {
          event: "checkout",
          ecommerce: {
            checkout: {
              actionField: { step: 3, option: option },
              products,
            },
          },
        },
      });
      const checkoutId = getBasketId();

      getCheckout(checkoutId || "").then(({ data }) => {
        dispatch(setCheckout(data));
        if (data.basket.items.find((item) => !item.in_stock)) {
          setIsErrorModalOpen(true);
        } else {
          setIsReady(true);
          // todo сервер не принимает:
          //  - адрес доставки
          finishOrder({
            delivery_id: delivery.id,
            payment_id: payment.id,
            shop_id: shop?.id,
            address_id: address?.id,
            cid,
            comment,
            basket_uuid: window.localStorage.getItem("basket_id"),
            phone: userData?.phone,
            bonuses: deductedBonusValue,
          })
            .then(({ data }) => {
              setOrderId(data.order_id);
              setPaymentLink(data.payment_link);
              if (payment?.code !== "yapay" && payment?.code !== "ysplit") {
                setIsLoading(false);
              }
              getUserData().then(({ data }) => {
                dispatch(setUserData(data));
                getCheckout(data.basket_uuid).then(({ data }) => {
                  dispatch(setCheckout(data));
                });
              });
              if (payment.code === "yapay" || payment.code === "ysplit") {
                window.location.href = data.payment_link;
              }
            })
            .catch(() => {
              setError(true);
            })
            .finally(() => {
              if (payment.code !== "yapay" && payment.code !== "ysplit") {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }
            });
        }
      });
    }
  }
  useEffect(() => {
    if (userWorkFlow) {
      if (!isAuthorized) {
        history.push(ROUTES.auth);
        TagManager.dataLayer({
          dataLayer: {
            event: "not-authorized",
          },
        });
      } else {
        TagManager.dataLayer({
          dataLayer: {
            event: "authorized",
          },
        });
      }
    }
  }, [userWorkFlow, isAuthorized, history]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  useEffect(() => {
    localStorage.removeItem("inCheckout");
    localStorage.removeItem("CheckoutWithData");
  }, []);

  function getFormatPhone(phoneNumber: string) {
    return phoneNumber.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g,
      "$1 $2 $3 $4 $5"
    );
  }

  const purchaseOrder = useCallback(() => {
    if (
      orderId &&
      typeof deliveryPrice !== "undefined" &&
      (typeof deliveryPrice === "string" || deliveryPrice >= 0) &&
      checkout.items.length > 0
    ) {
      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          ecommerce: {
            purchase: {
              actionField: {
                id: orderId,
                affiliation: "usual",
                revenue: checkout.resultPrice,
                shipping: deliveryPrice || 0,
                coupon: checkout.discountText || "",
              },
              products: products.map(
                ({ name, id, price, brand, category, size, quantity }) => {
                  return {
                    name,
                    id,
                    brand,
                    category,
                    size,
                    quantity,
                    price,
                    currency: "RUB",
                  };
                }
              ),
            },
          },
        },
      });
    }
  }, [checkout, deliveryPrice, orderId, products]);

  const deliveryErrorHandle = (state: boolean, errorMessage: string) => {
    setDeliveryError(state);
    setErrorMessage(errorMessage);
    if (state === true) {
      setIsErrorModalOpen(true);
    }
  };

  useEffect(() => {
    purchaseOrder();
  }, [purchaseOrder]);
  return (
    <>
      <HelmetProvider>
        <Helmet
          defer={false}
          title={"Оформить заказ - интернет-магазин Leform"}
          meta={[
            {
              name: "description",
              content: "Оформить заказ - интернет-магазин Leform",
            },
          ]}
        />
      </HelmetProvider>

      {isShowPreloader && <Preloader />}

      {!isShowPreloader &&
        !error &&
        (isLoading || !isReady) &&
        checkout.items.length === 0 && (
          <>
            <Layout>
              <h1>Товаров в корзине нет.</h1>
              <BackLinkEmpty onClick={() => void history.goBack()}>
                <div className="back-arrow"></div>
                <span>Назад</span>
              </BackLinkEmpty>
            </Layout>
          </>
        )}
      {(!isShowPreloader &&
        checkout.items.length !== 0 &&
        !error &&
        (isLoading || !isReady)) ||
      (!isShowPreloader &&
        checkout.items.length !== 0 &&
        !error &&
        !isReady &&
        payment?.code !== "yapay" &&
        payment?.code !== "ysplit") ? (
        <Layout>
          <BackLinkEmpty onClick={() => void history.goBack()}>
            <div className="back-arrow"></div>
            <span>Назад</span>
          </BackLinkEmpty>
          <CheckoutContent>
            <div className="personal_info p-45 mb-15 back--blue">
              <h1>Персональные данные</h1>
              <div className="personal_info-row">
                <p>
                  Имя:{" "}
                  <span>
                    {userData?.userName ? userData.userName : "Имя не указано"}
                  </span>
                </p>
                <p>
                  Телефон:{" "}
                  <span>
                    {userData?.phone
                      ? getFormatPhone(userData.phone)
                      : "Телефон не указан"}
                  </span>
                </p>
              </div>
              <p>
                Изменить данные вы можете в{" "}
                <LinkInPersonal to={ROUTES.personalData}>
                  личном кабинете
                </LinkInPersonal>
              </p>
            </div>
            <div className="mb-10">
              <Accordeon
                header={
                  <CheckoutHeadline className="p-45 back--blue">
                    Информация о доставке
                    <svg
                      width="20"
                      height="12"
                      viewBox="0 0 20 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L10 10L19 1"
                        stroke="black"
                        stroke-width="2"
                      />
                    </svg>
                  </CheckoutHeadline>
                }
                content={
                  <ChooseDelivery
                    onSelect={handleDelivery}
                    onSelectDelivery={setDeliveryPrice}
                    products={products}
                  />
                }
                isOpen={true}
              />
            </div>
            <div className="p-45-include mb-10 back--blue">
              <Accordeon
                header={
                  <CheckoutHeadline>
                    Способ оплаты
                    <svg
                      width="20"
                      height="12"
                      viewBox="0 0 20 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L10 10L19 1"
                        stroke="black"
                        stroke-width="2"
                      />
                    </svg>
                  </CheckoutHeadline>
                }
                content={
                  delivery && isShopId ? (
                    <div className="pt-30">
                      <ChoosePaymentMethod
                        className="payment_radio"
                        delivery={delivery}
                        onSelect={setPayment}
                        setDeliveryPrice={(delPrice) =>
                          setDeliveryPrice(delPrice)
                        }
                        onError={deliveryErrorHandle}
                      />
                    </div>
                  ) : (
                    <div className="pt-30">Вы не выбрали доставку</div>
                  )
                }
                isOpen={!!delivery}
              />
            </div>
            {userData?.orders_count &&
            userData.orders_count > 0 &&
            userData.bonuses?.balance &&
            userData.bonuses.balance > 0 ? (
              <OrderBonus
                bonusData={userData.bonuses}
                totalPrice={checkout.resultPrice}
                deductedBonusValue={deductedBonusValue}
                onChange={(value) => {
                  setDeductedBonusValue(value);
                }}
              />
            ) : (
              <></>
            )}
            <div className="button_container" id="button_container"></div>
            <div className="db-768">
              <LoaderButton
                disabled={isSubmitButtonDisabled}
                isLoading={isLoading}
                onClick={() => handleSubmit()}
                className="checkout"
                buttonText="Подтвердить"
              />
              <Text>
                Завершая оформление заказа, я подтверждаю ознакомление с{" "}
                <a
                  href={PRIVACY_POLICY}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  указанными условиями
                </a>
                .
              </Text>
            </div>
          </CheckoutContent>
          <OrderItemList
            checkout={checkout}
            deliveryPrice={deliveryPrice}
            bonusValue={deductedBonusValue}
          />
          <div className="dn-768">
            <LoaderButton
              disabled={isSubmitButtonDisabled}
              isLoading={isLoading}
              onClick={() => handleSubmit()}
              className="checkout"
              buttonText="Подтвердить"
            />
            <Text>
              Завершая оформление заказа, я подтверждаю ознакомление с{" "}
              <a
                href={PRIVACY_POLICY}
                target="_blank"
                rel="noopener noreferrer"
              >
                указанными условиями
              </a>
              .
            </Text>
          </div>
        </Layout>
      ) : (
        <></>
      )}

      {error && (
        <OrderErrorWrapper>
          <CheckoutHeadline className="text_align--center">
            Произошла ошибка
          </CheckoutHeadline>
          <ReturnLink
            variant="white"
            onClick={() => void history.push(ROUTES.home)}
          >
            Вернуться на главную страницу
          </ReturnLink>
        </OrderErrorWrapper>
      )}

      {isReady &&
        orderId &&
        payment?.code !== "yapay" &&
        payment?.code !== "ysplit" && (
          <OrderCompletedWrapper>
            <OrderCompleted orderNumber={orderId} paymentLink={paymentLink} />
            <ReturnLink
              variant="white"
              onClick={() => void history.push(ROUTES.home)}
            >
              Вернуться на главную страницу
            </ReturnLink>
          </OrderCompletedWrapper>
        )}
      {isErrorModalOpen && (
        <BasketErrorModal
          errorMessage={errorMessage}
          onClose={() => setIsErrorModalOpen(false)}
        />
      )}
    </>
  );
};

export default Checkout;
