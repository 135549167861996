import styled from "styled-components";

import { Box } from "../Box";

export const Block = styled(Box)`
  &:not(:first-child) {
    margin-top: 40px;
  }
`;

export const InnerBlock = styled(Box)`
  &:not(:first-child) {
    margin-top: 14px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const BoxWrapper = styled(Box)`
  &.mobileBox {
    > div {
      margin-top: 40px;
    }
  }
`;

export const InputContainer = styled(Box)`
  &:not(:first-child) {
    margin-top: 10px;
  }

  &.filterInner {
    width: 100%;

    @media (min-width: 1200px) {
      width: 240px;
    }
    @media (min-width: 1920px) {
      width: 316px;
    }
  }
`;
