import React, { FC } from "react";

import { Button } from "src/components";

import {
  Container,
  ReturnModalButton,
  Overlay,
  Title,
  ReturnLink,
  Text,
  BoldText,
  SubTitle,
  DocumentIcon,
  ReturnLinkWrapper,
  QuestionsText,
  PhoneLink,
  CloseIcon,
} from "./ReturnModal.styles";
import { ReturnModalProps } from "./ReturnModal.types";

const ReturnModal: FC<ReturnModalProps> = ({ handleModal, isRefundable }) => {
  return (
    <>
      <Overlay onClick={handleModal} />
      <Container>
        <ReturnModalButton onClick={handleModal}>
          <CloseIcon width="14" height="14" />
        </ReturnModalButton>
        {isRefundable && (
          <>
            <Title>Возврат заказа</Title>
            <Text>
              <BoldText>
                Возврат товара, приобретенного офлайн, происходит в магазинах по
                месту его покупки.
              </BoldText>
            </Text>
            <ReturnLinkWrapper>
              <ReturnLink
                target="_blank"
                href="https://leback.leform.ru/storage/%D0%97%D0%B0%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%B2%D0%BE%D0%B7%D0%B2%D1%80%D0%B0%D1%82_LEFORM.pdf"
              >
                ЗАЯВЛЕНИЕ НА ВОЗВРАТ
                <DocumentIcon />
              </ReturnLink>
            </ReturnLinkWrapper>
            <SubTitle>Возврат товара, приобретенного онлайн:</SubTitle>
            <Text>
              — <BoldText>лично по адресу:</BoldText> Москва, ул.
              Краснопролетарская, д.30, стр.1
            </Text>
            <Text>
              — <BoldText>курьерской службой</BoldText> (или иной службой
              доставки) по указанному адресу: Москва, ул.Краснопролетарская,
              д.30,стр.1;».
            </Text>
            <SubTitle>Для возврата товара дистанционным способом</SubTitle>
            <Text>— распечатайте и заполните заявление на возврат</Text>
            <Text>
              — проверьте сохранность товарного вида купленной вещи, в том числе
              упаковки
            </Text>
            <Text>
              — отправьте любой курьерской службой (СДЭК, DPD, EMS и тд.) по
              адресу: Москва, ул. Краснопролетарская, д.30, стр.1, магазин
              LEFORM;
            </Text>
            <Text>— имя получателя: ООО "ЛЕГПРО".</Text>
            <SubTitle>Доп.информация:</SubTitle>
            <Text>
              — при передаче в курьерскую службу убедитесь, что упаковка
              сохранила товарный вид;
            </Text>
            <Text>
              — коробка из-под обуви упаковывается в отдельную коробку
            </Text>
            <Text>— доставка оформляется «до двери»;</Text>
            <Text>
              — в случае, если требуется указать получателем физическое лицо,
              укажите «Нагаев Рамиль»
            </Text>
            <Text>— возврат осуществляется за счет клиента.</Text>
          </>
        )}
        {isRefundable === false && (
          <>
            <Title>Возврат недоступен</Title>
            <Text>
              <BoldText>
                Приносим свои извинения — обмен или возврат по этому заказу, к
                сожалению, недоступен.
              </BoldText>
            </Text>
            <SubTitle>Возможные причины:</SubTitle>
            <Text>— истек срок действия возврата;</Text>
            <Text>— товары не подлежат возврату: белье, носки и прочее;</Text>
          </>
        )}
        <QuestionsText>
          Если у вас остались вопросы, напишите нам в чат или позвоните по
          номеру <PhoneLink href="tel:88007551970">8 800 775-19-70</PhoneLink>
        </QuestionsText>
        <Button variant="white" onClick={handleModal}>
          Закрыть
        </Button>
      </Container>
    </>
  );
};

export default ReturnModal;
