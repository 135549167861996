import React, { useCallback, FC, FormEvent, useState, useEffect } from "react";

import { BorderlessMaskedInput } from "src/components";

import Armenia from "./image/Armenia.png";
import Belarus from "./image/Belarus.png";
import Kazahstan from "./image/Kazahstan.png";
import Russia from "./image/Russia.png";
import { Form, LoginButton, CustomSelect } from "./LoginForm.styles";
import { LoginFormProps } from "./LoginForm.types";

const LoginForm: FC<LoginFormProps> = (props) => {
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const {
    className,
    values,
    onChange,
    onSubmit,
    onPaste,
    isLoading,
    errors,
  } = props;

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  const handleWindowClick = useCallback(
    (event: MouseEvent) => {
      if (selectIsOpen) {
        const target = event.target as HTMLElement;
        if (
          !target.closest(".select--head") &&
          !target.closest(".select--content")
        ) {
          setSelectIsOpen(false);
        }
      }
    },
    [selectIsOpen]
  );

  useEffect(() => {
    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [handleWindowClick]);

  const arrayCountries = [
    {
      countryPng: Russia,
      id: 1,
      countryMask: "+7 (999) 999-99-99",
      countryCode: "Россия +7",
      codeLength: 11,
    },
    {
      countryPng: Belarus,
      id: 2,
      countryMask: "+375 (99) 999-99-99",
      countryCode: "Беларусь +375",
      codeLength: 12,
    },
    {
      countryPng: Kazahstan,
      id: 3,
      countryMask: "+7 (999) 999-99-99",
      countryCode: "Казахстан +7",
      codeLength: 11,
    },
    {
      countryPng: Armenia,
      id: 4,
      countryMask: "+374 (99) 99-99-99",
      countryCode: "Армения +374",
      codeLength: 11,
    },
  ];

  const [currentCountry, setCurrentCountry] = useState(arrayCountries[0]);

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <div className="relative">
        <BorderlessMaskedInput
          type="tel"
          name="phone"
          inputMode="numeric"
          onPaste={onPaste}
          autoComplete="tel"
          aria-label="Введите ваш телефон"
          placeholder="Введите ваш телефон*"
          value={values.phone}
          onChange={onChange}
          hasError={!!errors.phone}
          required
          mask={currentCountry.countryMask}
          className="pl-63"
        />
        <CustomSelect>
          <div
            role="none"
            onClick={() => {
              setSelectIsOpen(!selectIsOpen);
            }}
            className="absolute"
          >
            <div className="select--head">
              <h4>
                <img src={currentCountry.countryPng} alt="flag" />
                <svg
                  className={selectIsOpen ? "open" : ""}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.175 5.81641L8 9.64141L11.825 5.81641L13 6.99974L8 11.9997L3 6.99974L4.175 5.81641Z"
                    fill="black"
                  />
                </svg>
              </h4>
            </div>
          </div>
        </CustomSelect>
        {selectIsOpen && (
          <div className="select--content">
            <div className="overflow--scroll">
              {arrayCountries.map((
                country: any //  eslint-disable-line @typescript-eslint/no-explicit-any
              ) => (
                <p
                  role="none"
                  onClick={() => {
                    if (currentCountry.id !== country.id) {
                      setSelectIsOpen(false);
                      setCurrentCountry(country);
                      values.phone = "";
                      values.codeLength = country.codeLength;
                    }
                    if (currentCountry.id === country.id) {
                      setSelectIsOpen(false);
                      setCurrentCountry(country);
                      values.codeLength = country.codeLength;
                    }
                  }}
                  className={`option ${
                    // eslint-disable-next-line eqeqeq
                    country.id == currentCountry.id ? "option--select" : ""
                  }`}
                  key={country.id}
                >
                  <img src={country.countryPng} alt="flag" />
                  {country.countryCode}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
      <LoginButton type="submit" disabled={isLoading}>
        ВОЙТИ
      </LoginButton>
    </Form>
  );
};

export default LoginForm;
