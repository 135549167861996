import React, { FC } from "react";

import { Checkbox } from "src/components";
import { SelectOption } from "src/components/Select/Select.types";

import Glyphs from "../Icons/Glyphs";
import { FlexWrapper, IconWrapper } from "../Select/Select.styles";

import {
  ModalContainer,
  CloseButton,
  CloseIcon,
  Container,
  ModalInner,
  HeaderWrapper,
  Header,
  ButtonWrapper,
  SortingButton,
  InputContainer,
  LabelWrapper,
} from "./MobileCatalogSorting.styles";
import { MobileCatalogSortingProps } from "./MobileCatalogSorting.types";

const MobileCatalogSorting: FC<MobileCatalogSortingProps> = ({
  onClose,
  isLoading,
  sortingItems,
  sortingValue,
  onChangeSorting,
  onAcceptSorting,
}) => {
  const filteredSortingItems = sortingItems.filter((item) => item.value);

  const handleClearSorting = () => {
    onAcceptSorting({} as SelectOption);
  };

  const checkedValue = sortingValue.value || "";

  const labelOutput = (itemSort: SelectOption | undefined) => {
    if (!itemSort) {
      return <></>;
    }

    const isNews = itemSort.value === "news";
    const isChosen = checkedValue === (!isNews ? "olds" : "news");

    if (itemSort.value === "news" || itemSort.value === "olds") {
      return (
        <FlexWrapper isChosen={isChosen}>
          По дате
          <IconWrapper isChosen={!isChosen} isMobile={true}>
            {isNews ? <Glyphs.ArrowFilterUp /> : <Glyphs.ArrowFilterDown />}
          </IconWrapper>
        </FlexWrapper>
      );
    } else {
      return <>{itemSort.label}</>;
    }
  };

  const handleClick = (itemSort: SelectOption) => {
    onChangeSorting({
      label: itemSort.label,
      value: itemSort.value,
    });
  };

  return (
    <ModalContainer onClose={onClose} withoutScroll>
      <ModalInner>
        <Container>
          <HeaderWrapper>
            <Header>Сортировка</Header>
            <CloseButton onClick={onClose} aria-label="Закрыть">
              <CloseIcon />
            </CloseButton>
          </HeaderWrapper>

          {filteredSortingItems.map((itemSort) => {
            return (
              <InputContainer onClick={() => handleClick(itemSort)}>
                <Checkbox
                  name={"sort"}
                  label=""
                  type="radio"
                  value={`${itemSort.value}`}
                  checked={checkedValue === itemSort.value}
                  onChange={() =>
                    onChangeSorting({
                      label: itemSort.label,
                      value: itemSort.value,
                    })
                  }
                  disabled={isLoading}
                />
                <LabelWrapper>{labelOutput(itemSort)}</LabelWrapper>
              </InputContainer>
            );
          })}
        </Container>
        <ButtonWrapper>
          <SortingButton onClick={() => onAcceptSorting(sortingValue)}>
            Применить
          </SortingButton>
          <SortingButton variant="white" onClick={handleClearSorting}>
            Сбросить фильтры
          </SortingButton>
        </ButtonWrapper>
      </ModalInner>
    </ModalContainer>
  );
};

export default MobileCatalogSorting;
