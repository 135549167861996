import { CheckoutItem } from "src/types";

export type Checkout = {
  id: number;
  uuid: string;
  items: CheckoutItem[];
  discountText: string;
  price: number;
  resultPrice: number;
};

export enum CheckoutTypes {
  SET = "SET_CHECKOUT",
  CLEAR = "CLEAR_CHECKOUT",
}

export type SetCheckout = {
  type: CheckoutTypes.SET;
  payload: Checkout;
};

export type ClearCheckout = {
  type: CheckoutTypes.CLEAR;
};

export type CheckoutActions = SetCheckout | ClearCheckout;
