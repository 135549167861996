import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { Button } from "../Button";
import { Flex } from "../Flex";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Modal } from "../Modal";

import { SizeButtonProps } from "./AddToFavorites.types";

export const ModalContainer = styled(Modal)`
  background-color: ${({ theme }): string => theme.colors.white};
  padding: 40px 45px;
  width: 430px;
  min-height: 270px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1150px) {
    width: 100%;
    max-width: 420px;
    padding: 35px 20px;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 29px;
  right: 29px;

  &:hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
  stroke: white;
  stroke-width: 1px;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.01em;
  margin: 0 0 16px;
`;

export const AddButton = styled(Button)`
  width: 100%;
  height: 48px;
  font-size: 14px;
`;

export const SizeContainer = styled(Flex)`
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const SizeButton = styled(Button)<SizeButtonProps>`
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.01em;
  padding: 6px 2px 0;
  margin: 0 0 10px;
  border: 0;
  width: 37px;
  position: relative;
  color: ${({ theme }): string => theme.colors.grey};
  background-color: transparent !important;

  ${({ isActive }): FlattenInterpolation<ThemeProps<DefaultTheme>> | false =>
    isActive &&
    css`
      color: ${({ theme }): string => theme.colors.black};
      font-weight: bold;
    `};
`;
